input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.custom-checkbox-input {
  width: 13px;
  height: 13px;
  position: relative;

  &::before {
    font-family: "Material Icons";
    content: "\e5ca";
    font-size: 0.8em;
    color: white;
    font-weight: 500;
    position: absolute;
    background: white;
    border: 1px solid var(--x-border-secondary);
    border-radius: 1px;
    margin: auto;
    display: block;
    width: 13px;
    height: 13px;
    display: flex;
    align-items: center;
  }
  &.checked, &:checked {
    &::before {
      background: var(--x-primary);
      border: 1px solid var(--x-primary);
    }
  }
  &.disabled {
    &::before {
      background: var(--x-light-color);
      color: transparent;
    }
  }
  &.checked.disabled, &:checked.disabled {
    &::before {
      background-color: var(--x-primary);
      color: white;
      opacity: 0.5;
    }
  }
}
