// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato/fonts/Lato-Heavy.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato/fonts/Lato-HeavyItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold 800 900;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato/fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato/fonts/Lato-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato/fonts/Lato-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato/fonts/Lato-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 100 200 300 400;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato/fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 100 200 300 400;
  text-rendering: optimizeLegibility;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/material-icons/material-icons.woff2') format('woff2');
}
/* fallback */
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/material-icons/material-icons-outlined.woff2') format('woff2');
}
/* fallback */
@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/material-icons/material-icons-round.woff2') format('woff2');
}
/* fallback */
@font-face {
  font-family: 'Material Icons Two Tone';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/material-icons/material-icons-two-tone.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  // font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  // font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-round {
  font-family: 'Material Icons Round';
  font-weight: normal;
  font-style: normal;
  // font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-two-tone {
  font-family: 'Material Icons Two Tone';
  font-weight: normal;
  font-style: normal;
  // font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
