@import "./variables/color.scss";
@import "./variables/z-index.scss";

$body-color: $black;
$font-size-base: 1rem;
$font-weight-semibold: 600;
$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$font-family-base: 'Lato', sans-serif;

$sidebarSize: 240px;
$navbarHeight: 50px;
$navbarMobileHeight: 50px;
$inputHeight: 32px;
$progressHeight: 10px;
$progressHeightLarge: 15px;
$icon-sm: 15px;
$icon-md: 20px;
$icon-lg: 32px;
$icon-xl: 36px;
$icon-xlg: 48px;
$icon-2xlg: 52px;
$checkbox-md: 1;

$tableCellPaddingX: 0.4rem;
$tableCellPaddingY: 0.3rem;
$tableRowHeight: 50px;
$filterByMinWidth: 320px;

$spacer: 1rem;

$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 3,
  ),
  6: (
    $spacer * 5,
  ),
  7: (
    $spacer * 8,
  ),
);
