mat-expansion-panel {
  box-shadow: none!important;
  overflow: unset!important;

  .mat-expansion-panel-header,
  .mat-expansion-panel-content {
    font-family: inherit!important;
    font-size: inherit!important;
    line-height: inherit!important;
    letter-spacing: inherit!important;
    color: var(--x-text-color)!important;
    overflow: hidden;
  }

  .mat-expansion-panel-header {
    @apply block p-0 h-auto #{!important};

    &:hover {
      @apply bg-transparent #{!important};
    }

    .mat-content {
      @apply block mr-0;
    }
  }

  .mat-expansion-panel-body {
    @apply p-0;
  }
}
